import { Row, Col, List, Button, message } from "antd"
import { useState } from "react"
import Schedule from "../components/Schedule"
import { retrieveOrgSchedules } from "../requests";

const get_schedule_name_id = (schedules) => {
  console.log("get_schedule_name_id", schedules)
  var res = schedules.map((schedule) => {
    return {
      name: schedule.schedule_id,
      id: schedule.schedule_id
    }
  })
  return res
}

const Schedules = (props) => {
  const [selectedSchedule, setSelectedSchedule] = useState(null)
  const [scheduleData, setScheduleData] = useState(null)
  const [listSchedules, setListSchedules] = useState([])
  const [messageApi, contextHolder] = message.useMessage();
  console.log(scheduleData, listSchedules, selectedSchedule)
  if(scheduleData == null) {
    setTimeout(() => {
      messageApi.loading("retrieving schedules", 10)
    }, 5)
    retrieveOrgSchedules(props.common.api_token, props.common.project.selectedProject.id, props.common.oauth_id_token.__raw)
    .then((response) => {
      if (response.status === 200) {
        return response.json()
      }
    }).then((responseBody) => {
      console.log("responseBody", responseBody)
      messageApi.destroy()
      if(responseBody) {
        messageApi.success("", 4)
        setScheduleData(responseBody.schedules)
        setListSchedules(get_schedule_name_id(responseBody.schedules))
      }
    }).catch((reason) => {
      console.log("failure", reason)
      messageApi.destroy()
      messageApi.error("failure loading the schedule data")
    })
  }
  console.log("selectedSchedule", selectedSchedule)
  const onItemClick = (data) => {
    console.log("onItemClick.setSelectedSchedule", data)
    setSelectedSchedule(data)
  }
  const updateSelectedSchedule = (id, data) => {
    var idx = scheduleData.map(it => it.schedule_id).indexOf(id)
    var schedule_data = scheduleData
    schedule_data[idx] = data
    console.log("updateSelectedSchedule.data", data, schedule_data)
    setSelectedSchedule({
      name: data.schedule_id,
      id: data.schedule_id
    })
    const setter = (s_data) => {setTimeout(() => setScheduleData(s_data), 0)}
    setter(schedule_data)
  }
  const generateScheduleDetails = () => {
    if(selectedSchedule === null) {
      return <div></div>
    } else {
      const schedule = scheduleData.find((schedule) => schedule.schedule_id === selectedSchedule.id)
      console.log("generateScheduleDetails.schedule", schedule)
      return <Schedule schedule_data={schedule} common={props.common} update_selected_schedule={updateSelectedSchedule}/>
    }
  }
  return (<div>
    {contextHolder}
    <Row style={{ height: "90vh", padding: "0px" , marginLeft: 8, marginRight: 8, marginTop: 8}}>
      <Col span={8} style={{height: "90vh", overflow: "scroll", padding: "0px"}}>
        <List
          dataSource={listSchedules}
          bordered
          style={{backgroundColor: "white"}}
          renderItem={(item) => (
            <List.Item style={{padding: "0px"}}>
              <Button type="text" block={true} onClick={() => {onItemClick(item)}}>{item.name}</Button>
            </List.Item>
          )}
        />
      </Col>
      <Col offset={1} span={15} style={{height: "90vh"}}>
        {generateScheduleDetails()}
      </Col>
    </Row>
  </div>)
}

export default Schedules;